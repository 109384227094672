import * as React from 'react'
import {graphql} from 'gatsby'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ParallaxProvider} from 'react-scroll-parallax'

import {Seo, Layout, AGBPage} from '../components'
import {greenTheme} from '../theme'

const AGB = () => {
  const {t} = useTranslation()

  return (
    <ParallaxProvider>
      <Layout themeConfig={greenTheme}>
        <Seo title={t('pages.agb.seo.title')} description={t('pages.agb.seo.description')} />
        <AGBPage />
      </Layout>
    </ParallaxProvider>
  )
}

export default AGB

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
